
	import { Component, Prop, Mixins } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import { PixcapArticle } from '@/models/store/blog.interface';
	import ArticleMixin from '@/pages/blog/ArticleMixin';

	@Component({
		components: { BodyText },
	})
	export default class ArticleCardVertical extends Mixins(ArticleMixin) {
		@Prop() _article: PixcapArticle;
		created() {
			this.article = this._article;
		}
	}
