
	import { Mixins, Prop, Component } from 'vue-property-decorator';
	import debounce from 'lodash.debounce';
	import IconHeart from '@pixcap/ui-library/components/Icons/IconHeart.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import IconHeartGradient from '@pixcap/ui-library/components/Icons/IconHeartGradient.vue';
	import IconUnheart from '@pixcap/ui-library/components/Icons/IconUnheart.vue';
	import AuthenticationModalMixin from '@pixcap/ui-core/mixins/AuthenticationModalMixin';
	import { mapState } from 'vuex';
	import { actionsWrapper as CommunityActions } from '@/store/community/wrapper';
	import { ICommunityState, NAMESPACE as COMMUNITY_NAMESPACE, TargetDataKey } from '@/models/store/community.interface';

	@Component({
		name: 'CommunityLoveAction',
		components: { IconUnheart, IconHeartGradient, CaptionText, IconHeart },
		computed: {
			...mapState(COMMUNITY_NAMESPACE, {
				isInteractingCommunityItem: (state: ICommunityState) => state.isInteractingCommunityItem,
			}),
		},
	})
	export default class CommunityLoveAction extends Mixins(AuthenticationModalMixin) {
		@Prop() asset: any;
		@Prop({ type: Boolean, default: true }) isFromBrowsePage: boolean;
		@Prop({ type: Boolean, default: false }) isInDetailPage: boolean;

		isInteractingCommunityItem: ICommunityState['isInteractingCommunityItem'];

		onInteracting = debounce(this.handleInteracting, 300, {
			leading: false,
			trailing: true,
		});

		$refs: {
			refIconLike: HTMLElement;
		};

		get isLiked() {
			return this.asset.liked;
		}

		get isInvalidLike() {
			return (this.asset.likes <= 0 && this.isLiked) || this.asset.likes < 0;
		}

		get isSendingInteraction() {
			return this.isInteractingCommunityItem;
		}

		_checkUserPermission() {
			if (!this.isAuthenticated) {
				this.$pixcap.$services.authService.setOnAuthenticated(this.handleOnAuthSuccess.bind(this));
				this.showAuthenticationModal();
				return true;
			}
			return false;
		}

		handleOnAuthSuccess() {
			this.$pixcap.$services.authService.setOnAuthenticated(undefined);
			this.handleInteracting();
		}

		handleInteracting() {
			if (this._checkUserPermission() || this.isSendingInteraction || this.isInvalidLike) return;

			if (!this.isLiked) {
				this.$refs.refIconLike.classList.add('liked');
				setTimeout(() => {
					this.$refs.refIconLike.classList.remove('liked');
				}, 1000);
			} else {
				this.$refs.refIconLike.classList.add('unliked');
				setTimeout(() => {
					this.$refs.refIconLike.classList.remove('unliked');
				}, 1000);
			}

			CommunityActions.interactingCommunityItem(this.$store, {
				jobId: this.asset.jobId,
				targetDataKey: this.isInDetailPage
					? TargetDataKey.communityItem
					: this.isFromBrowsePage
					? TargetDataKey.communityItems
					: TargetDataKey.communityRelatedItemList,
				isLiked: !this.asset.liked,
			});
		}
	}
