
	import { Component, Vue, Watch } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';
	import Button from '@pixcap/ui-library/components/Button.vue';
	import IconLargeArrowLeft from '@pixcap/ui-library/components/Icons/IconLargeArrowLeft.vue';
	import { mutationsWrapper as CommunityMutations } from '@/store/community/wrapper';
	import CommunityItemDetails from '@/components/community/CommunityItemDetails.vue';
	import { NAMESPACE as COMMUNITY_NAMESPACE, ICommunityState } from '@/models/store/community.interface';

	@Component({
		name: 'CommunityItemDetailsModal',
		components: {
			IconLargeArrowLeft,
			Button,
			IconLargeArrowRight,
			CommunityItemDetails,
			BaseModal,
		},
		computed: {
			...mapState(COMMUNITY_NAMESPACE, {
				communityItems: (state: ICommunityState) => state.communityItems,
				communityRelatedItemList: (state: ICommunityState) => state.communityRelatedItemList,
				selectedCommunityItemSlug: (state: ICommunityState) => state.selectedCommunityItemSlug,
			}),
		},
	})
	export default class CommunityItemDetailsModal extends Vue {
		communityItems: ICommunityState['communityItems'];
		selectedCommunityItemSlug: ICommunityState['selectedCommunityItemSlug'];
		communityRelatedItemList: ICommunityState['communityRelatedItemList'];
		_handlePressStateButton;

		$refs: {
			refCommunityItemDetailsModal;
		};

		@Watch('selectedCommunityItemSlug')
		onItemSlugChange() {
			(this.$refs.refCommunityItemDetailsModal.$el as HTMLElement).scrollTop = 0;
			const currentItemIndex = window.history.state?.currentItemIndex === null ? -1 : window.history.state?.currentItemIndex;
			window.history.replaceState({ currentItemIndex }, '', this.selectedCommunityItemSlug);
		}

		get moreInspirations() {
			if (!this.communityRelatedItemList.listOfItems.length) return [];
			return this.communityRelatedItemList.listOfItems;
		}

		get listOfItems() {
			return this.communityItems.listOfItems;
		}

		get currentItemIndex() {
			return this.listOfItems.findIndex((item) => item.slug === this.selectedCommunityItemSlug);
		}

		get lastItemIndex() {
			return this.listOfItems.length - 1;
		}

		goPreviousItem(event) {
			event.preventDefault();
			event.stopPropagation();
			const currentItemIndex = window.history.state?.currentItemIndex === null ? -1 : window.history.state?.currentItemIndex;
			window.history.replaceState({ currentItemIndex }, '', this.listOfItems[this.currentItemIndex - 1].slug);
			CommunityMutations.setSelectedCommunityItemSlug(this.$store, this.listOfItems[this.currentItemIndex - 1].slug);
		}

		goNextItem(event) {
			event.preventDefault();
			event.stopPropagation();
			if (this.currentItemIndex === this.lastItemIndex) {
				return;
			}

			const currentItemIndex = window.history.state?.currentItemIndex === null ? -1 : window.history.state?.currentItemIndex;
			window.history.replaceState({ currentItemIndex }, '', this.listOfItems[this.currentItemIndex + 1].slug);
			CommunityMutations.setSelectedCommunityItemSlug(this.$store, this.listOfItems[this.currentItemIndex + 1].slug);
		}

		handleClose() {
			CommunityMutations.setSelectedCommunityItemSlug(this.$store, null);
			const backIndex = window.history.state.currentItemIndex;
			window.history.go(backIndex);
		}

		handlePressStateButton() {
			const locationPath = window.location.pathname;
			const routePath = this.$route.path;
			if (locationPath === routePath) CommunityMutations.setSelectedCommunityItemSlug(this.$store, null);
		}

		created() {
			this._handlePressStateButton = this.handlePressStateButton.bind(this);
			window.addEventListener('popstate', this._handlePressStateButton);
		}

		beforeDestroy() {
			window.removeEventListener('popstate', this._handlePressStateButton);
		}
	}
