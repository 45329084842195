
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import IconCaretLeft from '@pixcap/ui-library/components/Icons/IconCaretLeft.vue';
	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';
	import IconCaretRight from '@pixcap/ui-library/components/Icons/IconCaretRight.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import { PixcapArticle } from '@/models/store/blog.interface';
	import ArticleCardVertical from '@/components/blog/single-item/ArticleCardVertical.vue';

	@Component({
		components: { ArticleCardVertical, CaptionText, IconCaretRight, IconLargeArrowRight, IconCaretLeft, Button, BodyText, HeadingText },
	})
	export default class VerticalArticleList extends Vue {
		@Prop() icon: string;
		@Prop() title: string;
		@Prop() showAllUrl: string;
		@Prop({
			default() {
				return [];
			},
		})
		articles: PixcapArticle[];
	}
