
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { NAMESPACE as COMMUNITY_NAMESPACE, ICommunityState, COMMUNITY_RESOURCE_TYPE } from '@/models/store/community.interface';
	import CommunityAISceneItem from '@/components/community/CommunityAISceneItem.vue';
	import Community3DSceneItem from '@/components/community/Community3DSceneItem.vue';

	@Component({
		name: 'CommunityItem',
		components: {
			Community3DSceneItem,
			CommunityAISceneItem,
		},
		computed: {
			...mapState(COMMUNITY_NAMESPACE, {
				activeCommunityTab: (state: ICommunityState) => state.activeCommunityTab,
			}),
		},
	})
	export default class CommunityItem extends Vue {
		@Prop() asset: any;

		@Prop({ type: Boolean, default: true }) isFromBrowsePage: boolean;

		get isRenderingAISceneItem() {
			return this.asset.exportType === COMMUNITY_RESOURCE_TYPE.AI_SCENES;
		}
	}
