
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { NAMESPACE as COMMUNITY_NAMESPACE, ICommunityState, COMMUNITY_RESOURCE_TYPE } from '@/models/store/community.interface';

	import CommunityAISceneItemDetails from '@/components/community/CommunityAISceneItemDetails.vue';
	import Community3DSceneItemDetails from '@/components/community/Community3DSceneItemDetails.vue';
	import { capitaliseString } from '@/utils/string';
	import { setupBaseMetaTags } from '@/utils/seo';
	import { actionsWrapper as CommunityActions } from '@/store/community/wrapper';

	@Component({
		name: 'CommunityItemDetails',
		components: {
			Community3DSceneItemDetails,
			CommunityAISceneItemDetails,
		},
		computed: {
			...mapState(COMMUNITY_NAMESPACE, {
				isFetchingCommunityItemDetails: (state: ICommunityState) => state.isFetchingCommunityItemDetails,
				communityItem: (state: ICommunityState) => state.communityItem,
			}),
		},
	})
	export default class CommunityItemDetails extends Vue {
		@Prop({ type: String, default: '' }) itemSlug: string;
		@Prop({ type: Boolean, default: false }) openInModal: boolean;

		isFetchingCommunityItemDetails: ICommunityState['isFetchingCommunityItemDetails'];
		communityItem: ICommunityState['communityItem'];

		@Watch('itemSlug', { immediate: true })
		onItemSlugChange(val) {
			CommunityActions.getCommunityItem(this.$store, val);
		}

		get isRenderingAISceneItem() {
			return this.communityItem?.exportType === COMMUNITY_RESOURCE_TYPE.AI_SCENES;
		}

		get thumbnailUrl() {
			if (!this.communityItem) return '';
			if (this.communityItem.images?.length) return this.communityItem.images[0].fileUrl;
			return this.communityItem.original;
		}

		async fetch() {
			await CommunityActions.getCommunityItem(this.$store, this.itemSlug);
		}

		head() {
			const headMeta: any = {
				meta: [],
			};
			const metaTagsPaylod: any = {
				url: `${process.env.ROOT_URL}${this.$route.fullPath}`,
				noindex: true,
			};
			const itemDetails = this.communityItem;
			if (itemDetails) {
				metaTagsPaylod.image = this.thumbnailUrl;
				const keyWord = itemDetails.slug;
				metaTagsPaylod.title = `${capitaliseString(
					keyWord
				)} 3D element for graphic design. Web editor software to create 3D designs for ads, banners, and apps at Pixcap`;
				metaTagsPaylod.description = `Browse and download ${keyWord} 3D element for graphic designs. Pixcap makes it easy to create professional 3D designs, including ${keyWord}`;
			}
			setupBaseMetaTags(metaTagsPaylod, headMeta, this.$route);
			return headMeta;
		}
	}
