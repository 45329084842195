var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.isLiked ? 'You loved this. Click to undo' : 'Love'),expression:"isLiked ? 'You loved this. Click to undo' : 'Love'"}],ref:"refIconLike",class:[
		'community-action-like flex align-center justify-center',
		{
			'community-action-like--invalid': _vm.isInvalidLike,
		},
		{
			'community-action-like--detail-page': _vm.isInDetailPage,
		},
	],on:{"click":_vm.onInteracting}},[_c('CaptionText',{staticClass:"relative flex align-center gap-4",attrs:{"size":"medium","weight":"semibold"}},[(_vm.isLiked)?[_c('IconHeartGradient',{staticClass:"unliked-icon",attrs:{"width":"20","height":"20"}}),_vm._v(" "),_c('IconUnheart',{staticClass:"unliked-animation",attrs:{"width":"20","height":"20","fill":"#00000"}})]:[_c('IconHeart',{attrs:{"width":"20","height":"20","fill":"var(--pixcap-neutral-700)"}}),_vm._v(" "),_vm._l((3),function(key){return _c('IconHeartGradient',{key:key,class:`heart-animation heart-animation-${key}`,attrs:{"width":"20","height":"20"}})})],_vm._v(" "),_c('span',{class:{ 'community-action-like--count': _vm.isLiked }},[_vm._v(_vm._s(_vm.asset.likes))])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }