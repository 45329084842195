
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import IconArrowLeft from '@pixcap/ui-library/components/Icons/IconArrowLeft.vue';
	import IconArrowRight from '@pixcap/ui-library/components/Icons/IconArrowRight.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import PixcapDivThumbnail from '@pixcap/ui-library/components/PixcapDivThumbnail.vue';
	import IntersectComponent from '@pixcap/ui-core/plugins/intersect-component/IntersectComponent.vue';

	import { CommunityThumbnail, COMMUNITY_RESOURCE_TYPE } from '@/models/store/community.interface';

	@Component({
		name: 'CommunityItemThumbnail',
		components: {
			BodyText,
			IconArrowLeft,
			IconArrowRight,
			IntersectComponent,
			PixcapDivThumbnail,
		},
	})
	export default class CommunityItemThumbnail extends Vue {
		@Prop({ type: Array, default: () => [] }) thumbnails: CommunityThumbnail[];
		@Prop({ type: String, default: '' }) thumbnailAltTag: string;
		@Prop({ type: String, default: null }) original: string;
		@Prop({ default: false }) isAnimation: string;
		@Prop({}) resourceType: COMMUNITY_RESOURCE_TYPE;

		COMMUNITY_RESOURCE_TYPE = COMMUNITY_RESOURCE_TYPE;

		activeIndex = 0;
		isInViewPort = true;

		$refs: {
			images: PixcapDivThumbnail;
			container: HTMLElement;
		};

		get isRenderingAISceneList() {
			return this.resourceType === COMMUNITY_RESOURCE_TYPE.AI_SCENES;
		}

		get allowControls() {
			return this.thumbnails.length > 1;
		}

		get lastThumbnailsIndex() {
			return this.thumbnails.length - 1;
		}

		get originalTransform() {
			return {
				fileId: '',
				variantId: '',
				fileUrl: this.original || '',
			};
		}

		goLeft(event) {
			if (this.activeIndex === 0) {
				return;
			}
			const previousImageIndex = this.activeIndex - 1;
			const previousImageX = this.$refs.images[this.activeIndex - 1].$el.getBoundingClientRect().width;
			this.$refs.container.scrollTo({ behavior: 'smooth', left: previousImageX * previousImageIndex });
			this.activeIndex -= 1;
			return event.preventDefault();
		}

		goRight(event) {
			if (this.activeIndex === this.lastThumbnailsIndex) {
				return;
			}

			const nextImageIndex = this.activeIndex + 1;
			const nextImageX = this.$refs.images[this.activeIndex + 1].$el.getBoundingClientRect().width;
			this.$refs.container.scrollTo({ behavior: 'smooth', left: nextImageX * nextImageIndex });
			this.activeIndex += 1;
			return event.preventDefault();
		}

		created() {
			if (this.isRenderingAISceneList) {
				if (this.original && this.thumbnails[this.lastThumbnailsIndex].fileUrl !== this.original) {
					this.thumbnails.push(this.originalTransform);
				}
			}
		}
	}
