
	import { Component, Prop, Vue } from 'vue-property-decorator';

	@Component({
		name: 'CommunityAuthorAvatar',
	})
	export default class CommunityAuthorAvatar extends Vue {
		@Prop() authorAvatar: string;
		@Prop() authorName: string;

		get firstLetter() {
			if (!this.authorName) return '';
			return this.authorName.slice(0, 1).toUpperCase();
		}
	}
