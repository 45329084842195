
	import { Prop, Component, Mixins } from 'vue-property-decorator';

	import Button from '@pixcap/ui-library/components/Button.vue';
	import IconArrowLeft from '@pixcap/ui-library/components/Icons/IconArrowLeft.vue';
	import IconArrowRight from '@pixcap/ui-library/components/Icons/IconArrowRight.vue';
	import { downloadDirectFromUrl } from '@pixcap/ui-core/utils/WindowUtils';
	import AuthenticationModalMixin from '@pixcap/ui-core/mixins/AuthenticationModalMixin';
	import PixcapDivThumbnail from '@pixcap/ui-library/components/PixcapDivThumbnail.vue';
	import { actionsWrapper as LibraryActions } from '@/store/pixcapassets/wrapper';
	import { COMMUNITY_RESOURCE_TYPE } from '@/models/store/community.interface';
	import LibraryTabs from '@/components/pixcap-library/library-files/LibraryTabs.vue';

	@Component({
		components: { LibraryTabs, IconArrowRight, IconArrowLeft, Button, PixcapDivThumbnail },
	})
	export default class CommunityItemDetailsThumbnails extends Mixins(AuthenticationModalMixin) {
		@Prop({ type: Array, default: () => [] }) thumbnails: { thumbnailUrl: string; alt: string; fileId: string }[];
		@Prop({ default: null }) original: string;
		@Prop({}) resourceType: COMMUNITY_RESOURCE_TYPE;

		activeIndex = 0;
		tabs = [
			{
				name: 'AI Images',
				value: 'ai-images',
			},
			{
				name: 'Original 3D Scene',
				value: 'original',
			},
		];

		activeThumbnailTab = this.tabs[0].value;

		$refs: {
			images: PixcapDivThumbnail;
			refThumbnailsContainer: HTMLElement;
		};

		get lastThumbnailsIndex() {
			return this.thumbnails.length - 1;
		}

		get isRenderingAISceneList() {
			return this.resourceType === COMMUNITY_RESOURCE_TYPE.AI_SCENES;
		}

		handleChangeTab(tab) {
			this.activeThumbnailTab = tab;
		}

		swipeHandler(direction) {
			if (direction === 'left') this.goRightThumbnail(null);
			if (direction === 'right') this.goLeftThumbnail(null);
		}

		navigateThumbnails(index: number) {
			if (this.activeIndex === index) {
				return;
			}
			this.activeIndex = index;

			this.$refs.images[this.activeIndex].$el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		}

		goLeftThumbnail(event) {
			if (!event) event = window.event;
			if (this.activeIndex === 0) {
				return;
			}
			const previousImageIndex = this.activeIndex - 1;
			const previousImageX = this.$refs.images[this.activeIndex - 1].$el.getBoundingClientRect().width;
			this.$refs.refThumbnailsContainer.scrollTo({ behavior: 'smooth', left: previousImageX * previousImageIndex });
			this.activeIndex -= 1;
			return event.preventDefault();
		}

		goRightThumbnail(event) {
			if (!event) event = window.event;
			if (this.activeIndex === this.lastThumbnailsIndex) {
				return;
			}

			const nextImageIndex = this.activeIndex + 1;
			const nextImageX = this.$refs.images[this.activeIndex + 1].$el.getBoundingClientRect().width;
			this.$refs.refThumbnailsContainer.scrollTo({ behavior: 'smooth', left: nextImageX * nextImageIndex });
			this.activeIndex += 1;
			return event.preventDefault();
		}

		async handleDownload() {
			if (this._checkUserPermission('ai-images')) return;
			if (this.isRenderingAISceneList) {
				const image = this.thumbnails[this.activeIndex];
				const { presignedUrl } = await LibraryActions.fetchPresignedFile(this.$store, image.fileId);
				downloadDirectFromUrl(presignedUrl, image.alt);
			} else {
				const image = this.thumbnails[this.activeIndex];
				downloadDirectFromUrl(image.fileId, image.alt);
			}
		}

		handleDownloadOriginal() {
			if (this._checkUserPermission('original')) return;

			const image = this.original;
			downloadDirectFromUrl(image, image);
		}

		handleOnAuthSuccess(callback) {
			this.$pixcap.$services.authService.setOnAuthenticated(undefined);
			if (callback === 'ai-images') this.handleDownload();
			else this.handleDownloadOriginal();
		}

		_checkUserPermission(callback) {
			if (!this.isAuthenticated) {
				this.$pixcap.$services.authService.setOnAuthenticated(this.handleOnAuthSuccess.bind(this, callback));
				this.showAuthenticationModal();
				return true;
			}
			return false;
		}
	}
