
	import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator';
	import IconLink from '@pixcap/ui-library/components/Icons/IconLink.vue';
	import { copyTextToClipboard } from '@pixcap/ui-core/utils/WindowUtils';
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';

	@Component({
		name: 'CommunityShareAction',
		components: { CaptionText, IconCheck, IconLink },
	})
	export default class CommunityShareAction extends Vue {
		@Prop() slug: string;
		@Prop({ type: Boolean, default: false }) isInDetailPage: boolean;

		@InjectReactive() isDesktopScreen: boolean;

		showLinkCopied: boolean = false;

		get tooltipConfig() {
			if (this.isInDetailPage)
				return {
					content: 'Link copied!',
					show: !this.isDesktopScreen && this.showLinkCopied,
					trigger: [],
				};
			return {
				content: this.showLinkCopied ? 'Link copied!' : 'Copy link',
				show: this.showLinkCopied,
				trigger: this.showLinkCopied ? 'click' : 'hover',
			};
		}

		onShareUrl() {
			this.showLinkCopied = true;
			const textToCopy = `${window.location.origin}/community/${this.slug}`;
			copyTextToClipboard(textToCopy)?.then(() => {
				setTimeout(() => {
					this.showLinkCopied = false;
				}, 1000);
			});
		}
	}
