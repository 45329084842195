
	import { Vue, Component, Prop } from 'vue-property-decorator';
	import { copyTextToClipboard } from '@pixcap/ui-core/utils/WindowUtils';
	import IconCopy from '@pixcap/ui-library/components/Icons/IconCopy.vue';

	@Component({
		name: 'CommunityCopyPromptAction',
		components: { IconCopy },
	})
	export default class CommunityCopyPromptAction extends Vue {
		@Prop() prompt: string;

		showPromptCopied: boolean = false;

		onPromptCopyText() {
			this.showPromptCopied = !this.showPromptCopied;
			copyTextToClipboard(this.prompt)?.then(() => {
				setTimeout(() => {
					this.showPromptCopied = false;
				}, 1000);
			});
		}
	}
