
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { mapGetters } from 'vuex';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import { NAMESPACE as AUTH_NAMESPACE, GetterTypes as AuthGetterTypes } from '@pixcap/ui-core/models/store/auth.interface';
	import CommunityItemThumbnail from '@/components/community/CommunityItemThumbnail.vue';
	import CommunityAuthorAvatar from '@/components/community/CommunityAuthorAvatar.vue';
	import { COMMUNITY_RESOURCE_TYPE } from '@/models/store/community.interface';
	import { mutationsWrapper as CommunityMutations } from '@/store/community/wrapper';
	import CommunityLoveAction from '@/components/community/actions/CommunityLoveAction.vue';
	import CommunityShareAction from '@/components/community/actions/CommunityShareAction.vue';

	@Component({
		name: 'Community3DSceneItem',
		components: {
			CommunityShareAction,
			CommunityLoveAction,
			CommunityAuthorAvatar,
			CommunityItemThumbnail,

			BodyText,
		},
		computed: {
			...mapGetters(AUTH_NAMESPACE, {
				isAuthenticated: AuthGetterTypes.IS_AUTHENTICATED,
			}),
		},
	})
	export default class Community3DSceneItem extends Vue {
		@Prop() asset: any;

		@Prop({ type: Boolean, default: true }) isFromBrowsePage: boolean;

		COMMUNITY_RESOURCE_TYPE = COMMUNITY_RESOURCE_TYPE;

		handleOpenItem(e: MouseEvent, url: string) {
			if (e.ctrlKey) return;
			e.preventDefault();
			e.stopPropagation();
			const currentItemIndex = window.history.state?.currentItemIndex == null ? -1 : window.history.state?.currentItemIndex - 1;
			window.history.pushState({ currentItemIndex }, '', url);
			CommunityMutations.setSelectedCommunityItemSlug(this.$store, this.asset.slug);
		}
	}
